import { userConstant } from "../Action/constant";

const initialState = {
    AdminData: "",
    UserData: "",
    chapter_content: "",
    chapter_express_content: "",
    view_type: "",
    htmlContent: '',
    main_book_id: "",
    nameContent: {
        chapter_name: "",
        HeaderOne: "",
        HeaderTwo: "",
        HeaderThree: ""
    },
    breadCrumContent: [],
    customArray: [],
    regExpArray: [],
    isRender: false
}

export const singleReducer = (state = initialState, action) => {
    switch (action.type) {
        case userConstant.ADMIN_AUTH_SUCCESS:
            return {
                ...state,
                AdminData: action.payload
            };

        case userConstant.USER_AUTH_SUCCESS:
            return {
                ...state,
                UserData: action.payload
            };
        case userConstant.RESET_USER_AUTH:
            return {
                ...state,
                UserData: ""
            };
        default:
            return state;
    }
}


export const bookTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case userConstant.BOOK_TYPE:
            return {
                ...state,
                view_type: action.payload
            };
        default:
            return state;
    }
}
export const htmlContentReducer = (state = initialState, action) => {

    switch (action.type) {
        case userConstant.HTML_CONTENT:
            return {
                ...state,
                htmlContent: action.payload
            };
        default:
            return state;
    }
}


export const nameReducer = (state = initialState, action) => {

    switch (action.type) {
        case userConstant.HTML_NAME:
            return {
                ...state,
                nameContent: action.payload
            };
        default:
            return state;
    }
}

export const InstructorMainBookReducer = (state = initialState, action) => {
    switch (action.type) {
        case userConstant.MAIN_BOOK_ID:
            return {
                ...state,
                main_book_id: action.payload
            };
        default:
            return state;
    }
}

export const breadcrumbReducer = (state = initialState, action) => {
    switch (action.type) {
        case userConstant.BREAD_CRUM:
            return {
                ...state,
                breadCrumContent: action.payload
            };
        default:
            return state;
    }
}
export const customSequenceReducer = (state = initialState, action) => {
    switch (action.type) {
        case userConstant.CUSTOM_ARRAY:
            return {
                ...state,
                customArray: action.payload
            };
        default:
            return state;
    }
}
export const regExpSequenceReducer = (state = initialState, action) => {
    switch (action.type) {
        case userConstant.REG_EXP_ARRAY:
            return {
                ...state,
                regExpArray: action.payload
            };
        default:
            return state;
    }
}

export const renderReducer = (state = initialState, action) => {
    switch (action.type) {
        case userConstant.IS_RENDER:
            return {
                ...state,
                isRender: action.payload
            };
            default:
                return state;
    }
}