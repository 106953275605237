export const userConstant = {
    ADMIN_AUTH_SUCCESS: "ADMINAUTHSUCCESS",
    USER_AUTH_SUCCESS: "USERAUTHSUCCESS",
    RESET_USER_AUTH: "RESETUSERAUTH",
    CONTENT_TYPE: "CUSTOM_CONTENT",
    BOOK_TYPE: "BOOK_TYPE",
    HTML_CONTENT: "HTML_CONTENT",
    HTML_NAME: 'HTML_NAME',
    MAIN_BOOK_ID: "MAIN_BOOK_ID",
    BREAD_CRUM: "BREAD_CRUM",
    CUSTOM_ARRAY: "CUSTOM_ARRAY",
    REG_EXP_ARRAY: "REG_EXP_ARRAY",
    IS_RENDER: 'IS_RENDER'
}