import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
// import { Storage } from 'src/Storage';
import rootReducer from './Reducer';
import { persistReducer, persistStore } from 'redux-persist';
import storage from "redux-persist/lib/storage";

const initialState = {};

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];

export const store = createStore(
  persistedReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
  )
);

export const persistor = persistStore(store);
