// material
import { styled } from '@mui/material/styles';
import { Stack, Divider, Typography } from '@mui/material';
// component
import { oauthServices } from 'src/Services/ApiHandlers/oauthServices';
import { GoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { userAuthAction } from 'src/Redux/Action/storageAction';
import { useDispatch } from 'react-redux';
import { Storage } from 'src/Storage';
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(() => ({
  display: 'block',
  padding: '10px',
}));

export default function AuthSocial({ redirectURL = '/' }) {
  const [googleCred, setGoogleCred] = useState();
  const [googleBtnWidth, setGoogleBtnWidth] = useState(0);
  const dispatch = useDispatch();
  function getGoogleCred() {
    const postData = {
      credential: googleCred,
    };
    oauthServices
      .googleAuth(postData)
      .then((response) => {
        if (response.status === 200) {
          toast.success('User logged in successfully.');
          dispatch(userAuthAction(JSON.stringify(response.data.data)));
          Storage.set('authToken', JSON.stringify(response.data.data));
          setGoogleCred();
          setTimeout(() => {
            window.location.href = redirectURL;
          }, 500);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.log('Err...>', error);
      });
  }

  useEffect(() => {
    setGoogleBtnWidth(document.querySelector('.buttonHolder').clientWidth - 20);
  }, []);

  useEffect(() => {
    if (googleCred) getGoogleCred();
  }, [googleCred]);

  return (
    <div className="socialBtn">
      <Divider className="buttonHolder" sx={{ mt: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider>
      <ContentStyle>
        <Stack direction="row" spacing={2}>
          <GoogleLogin
            className="googleBtn"
            onSuccess={(credentialResponse) => {
              setGoogleCred(credentialResponse.credential);
            }}
            logo_alignment={'center'}
            width={googleBtnWidth}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </Stack>
      </ContentStyle>
    </div>
  );
}
