// import { DataService } from '../dataService';

import axios from 'axios';

const authBase = process.env.REACT_APP_API_BASEURL;

export const oauthServices = {
  googleAuth: (data) => {
    return axios.post(authBase + 'google/auth', data);
  },

  facebookAuth: (data) => {
    return axios.post(authBase + 'facebook/auth', data);
  },
};
