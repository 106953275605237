import { userConstant } from "./constant"

export const adminAuthAction = (payload) => ({
    type: userConstant.ADMIN_AUTH_SUCCESS,
    payload: payload
})
export const userAuthAction = (payload) => ({
    type: userConstant.USER_AUTH_SUCCESS,
    payload: payload
})
export const resetAuthAction = () => ({
    type: userConstant.RESET_USER_AUTH,
})
