import { styled } from '@mui/material/styles';
import { Card, Typography, Stack, Button } from '@mui/material';
import { LoginForm } from '../auth/login';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Iconify from '../../components/Iconify';
import Page from 'src/components/Page';
import useResponsive from 'src/hooks/useResponsive';
import { useEffect } from 'react';
import AuthSocial from '../auth/AuthSocial';
import { useTranslation } from 'react-i18next';

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(6, 0),
  maxWidth: '400px',
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0, 2, 2, 2),
  borderRadius: '5px',
}));

const LoginModal = ({ open, setOpen, setRegister, redirectURL = '/' }) => {
  const { t } = useTranslation();
  const smUp = useResponsive('up', 'sm');

  const goToRegister = () => {
    setOpen(false);
    setRegister(true);
  };

  useEffect(() => {
    if (window.location.pathname === '/forget-password') {
      setOpen(false);
    }
  }, [window.location.pathname]);

  return (
    <Page title="Login">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <ContentStyle>
            <SectionStyle>
              <Stack direction="row" alignItems="center" justifyContent="end">
                <Button onClick={() => setOpen(false)} sx={{ mt: 2 }}>
                  <Iconify icon="eva:close-fill" className="user-btn" width={25} height={25} />
                </Button>
              </Stack>

              <Typography variant="h3" gutterBottom align="center">
                {t('sign_in')}
              </Typography>

              <Typography align="center" sx={{ color: 'text.secondary', mb: 5 }}>
                {t('login_h1')}
              </Typography>

              <LoginForm redirectURL={redirectURL} />

              <AuthSocial redirectURL={redirectURL} />

              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                {t('login_dont_have_account')} &nbsp;
                <Button
                  color="success"
                  sx={{ mb: '3px', '&:hover': { color: 'green' } }}
                  variant="text"
                  onClick={() => goToRegister()}
                >
                  {t('get_started')}
                </Button>
              </Typography>
              {!smUp && (
                <Stack direction="row" alignItems="center" justifyContent="center">
                  <Typography variant="body2" align="center" sx={{ mt: 3, mr: 2 }} className="Lmargin">
                    {t('dont_have_account')}
                  </Typography>
                  <Typography>
                    <Button
                      color="success"
                      sx={{ mb: '3px', '&:hover': { color: 'green' } }}
                      variant="text"
                      onClick={() => goToRegister()}
                    >
                      {t('get_started')}
                    </Button>
                  </Typography>
                </Stack>
              )}
            </SectionStyle>
          </ContentStyle>
        </Fade>
      </Modal>
    </Page>
  );
};

export default LoginModal;
