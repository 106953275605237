import { LoadingButton } from '@mui/lab';
import { useState, useEffect } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Autocomplete,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
} from '@mui/material';
// components
import ReactPaginate from 'react-paginate';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// mock
import { userService } from 'src/Services/ApiHandlers/userService';
import DeleteModal from 'src/sections/PopUp/DeleteModal';
import { toast } from 'react-toastify';
import { adminBannerServices } from 'src/Services/ApiHandlers/adminBannerServices';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { adminBookService } from 'src/Services/ApiHandlers/adminBookService';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const DescriptionLayout = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  width: '300px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Image_Url = process.env.REACT_APP_API_IMAGEURL;
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'book_name', label: 'Book Name', alignRight: false },
  { id: 'book_description', label: 'Description', alignRight: false },
  { id: 'action', label: 'Actions', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function BannerSlideList() {
  const [order, setOrder] = useState('asc');

  // const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('full_name');

  const [errorBook, setErrorBook] = useState('');
  const [isSubmitting, setIsSubmitting] = useState('');
  // const [searchValue, setSearchValue] = useState({
  //     value: '',
  //     typing: false,
  //     typingTimeout: 0,
  // });
  const [openModal, setOpenModal] = useState(false);
  const [deleteIds, setDeleteIds] = useState(false);
  const [bookItem, setBookItem] = useState();
  const [pagination, setPagination] = useState({ page: 1, limit: 5 });
  const [bannerList, setBannerList] = useState([]);
  const [bookList, setBookList] = useState([]);

  const getBannerList = (currentPage = pagination.page, search, sort) => {
    let postData = {
      page: currentPage,
      limit: pagination.limit,
    };
    // let sortObject = {};
    if (search) {
      postData['search'] = search;
    }
    // if (sort) {
    //     sortObject['full_name'] = sort;
    // }
    adminBannerServices
      .getBannerApi(postData)
      .then((response) => {
        setBannerList(response.data);
        setPagination({ ...pagination, ...response.pagination });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBookList = () => {
    adminBookService
      .getBookListApi()
      .then((response) => {
        if (response.code === 200) {
          if (response.data && Array.isArray(response.data) && response.data.length > 0) {
            let array = response.data.map((element) => ({ title: element.book_name, id: element._id }));
            setBookList(array);
          }
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getBannerList();
    getBookList();
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    getBannerList('', '', isAsc ? 1 : -1);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (selectedObject) => {
    getBannerList(selectedObject.selected + 1, '');
  };

  const openDeleteModal = (id) => {
    setOpenModal(true);
    setDeleteIds(id);
  };

  const deleteHandler = () => {
    adminBannerServices
      .deleteBannerApi(deleteIds)
      .then((response) => {
        if (response.code === 200) {
          toast.success('Banner has been removed successfully');
          setOpenModal(false);
          getBannerList();
          setBookItem();
        } else {
          toast.success(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const assignHandler = (event) => {
    setBookItem(event?.id);
    event?.id ? setErrorBook(false) : setErrorBook(true);
  };

  const addBookHandler = () => {
    if (bookItem) {
      setErrorBook('');
    } else {
      setErrorBook('Book is required');
    }
    if (bookItem) {
      setIsSubmitting(true);
      adminBannerServices
        .assingBannerApi(bookItem)
        .then((response) => {
          if (response.code === 201) {
            toast.success('Banner has been Assign successfully');
            getBannerList();
            setIsSubmitting(false);
            setBookItem();
          } else {
            toast.error(response.message);
            setIsSubmitting(false);
            setBookItem();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const defaultProps = {
    options: bookList,
    getOptionLabel: (option) => option.title,
  };

  const isUserNotFound = bannerList.length === 0;
  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="end" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Banner Slide Management
          </Typography>
          <Stack direction="row" alignItems="end" className="bannerautocomplete">
            <div>
              <Autocomplete
                sx={{ width: 200 }}
                {...defaultProps}
                id="disable-close-on-select"
                value={bookItem}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField value={bookItem} {...params} label="Select Book" variant="standard" />
                )}
                onChange={(event, newValue) => {
                  assignHandler(newValue);
                }}
              />
              {errorBook && <p className="custom-error m-0">{errorBook}</p>}
            </div>

            <LoadingButton
              type="button"
              size="large"
              variant="contained"
              sx={{ height: 35 }}
              className="ms-3 px-2 py-1"
              loading={isSubmitting}
              startIcon={<Iconify icon="eva:plus-fill" className="user-btn mr-0" />}
              onClick={() => addBookHandler()}
            >
              Add Book
            </LoadingButton>
          </Stack>

          {/* <Button
                        variant="contained"
                        component={RouterLink}
                        to="add-user-management"
                        startIcon={<Iconify icon="eva:plus-fill" ClassName="user-btn" />}
                        className="hoverbtn"
                    >
                        New User
                    </Button> */}
        </Stack>

        <Card>
          {/* <UserListToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                        setOpenModal={setOpenModal}
                    /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }} className="fixedtable">
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  // rowCount={userList.length}
                  // numSelected={selected.length}
                  onRequestSort={(e) => handleRequestSort(e)}
                  // onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {bannerList &&
                    bannerList.length > 0 &&
                    bannerList.map((row, key) => {
                      const { _id, book_name, book_description, book_image } = row;
                      //const isItemSelected = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={key}
                          tabIndex={-1}
                          role="checkbox"
                          //selected={isItemSelected}
                          // aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, _id)} />
                                                        </TableCell> */}
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2} sx={{ marginLeft: 2 }}>
                              <Avatar alt={book_name} src={Image_Url + book_image} />
                              <Typography variant="subtitle2" noWrap>
                                {book_name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">
                            <CustomWidthTooltip title={book_description}>
                              <DescriptionLayout variant="subtitle2">{book_description}</DescriptionLayout>
                            </CustomWidthTooltip>
                          </TableCell>

                          <TableCell align="left">
                            {/* <UserMoreMenu openDeleteModal={(e) => openDeleteModal(e)} ids={_id} /> */}
                            {/* 
                                                        <Button type="button " >
                                                            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                                                            Assign
                                                        </Button> */}

                            <Button onClick={() => openDeleteModal(_id)} type="button" className="actionbtn">
                              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                            </Button>
                            {/* <BootstrapTooltip title={isAssign}>
                                                            
                                                        </BootstrapTooltip> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>

                {/* <TableFooter>

                                </TableFooter> */}

                {/* {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>
          {bannerList && bannerList.lenght > 0 && pagination.totalRecord > pagination.limit && (
            <ReactPaginate
              initialPage={pagination.page - 1}
              pageCount={pagination.totalRecord / pagination.limit}
              pageRange={2}
              marginPagesDisplayed={2}
              onPageChange={handleChangePage}
              containerClassName={'pagination-container'}
              previousLinkClassName={'page'}
              breakClassName={'page'}
              nextLinkClassName={'page'}
              pageClassName={'page'}
              disabledClassNae={'disabled'}
              activeClassName={'active'}
            />
          )}
        </Card>
      </Container>
      <DeleteModal setOpen={setOpenModal} open={openModal} deleteHandler={deleteHandler} />
    </Page>
  );
}
