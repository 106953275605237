import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { authServices } from 'src/Services/ApiHandlers/authServices';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
// import { Storage } from 'src/Storage';

// ----------------------------------------------------------------------

export default function RegisterForm({ setLogin, setOpen }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onlyCharacter = /^[a-zA-Z ]*$/;

  const RegisterSchema = Yup.object().shape({
    fullName: Yup.string().min(3, 'Too Short!').max(30, 'Too Long!').required('Full name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        'Atleast 1 Capital letter, 1 Small letter, 1 Special character and 1 Number'
      ),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password must match')
      .required('Confirm Password is required'),
    username: Yup.string()
      .min(3, 'Username must be atleast 3 character !')
      .max(20, 'Username can not be more than 20 character !')
      .required('Username is required'),
  });

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      password: '',
      username: '',
      confirmpassword: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      setIsSubmitting(true);
      const postData = {
        full_name: values.fullName,
        email: values.email,
        password: values.password,
        user_name: values.username,
      };
      authServices
        .registerReaderAuth(postData)
        .then((response) => {
          if (response.code === 201) {
            // Storage.set("authToken", JSON.stringify(response.data))
            setOpen(false);
            toast.success('User registered successfully ! please login to continue');
            setIsSubmitting(false);
            setLogin(true);
            // navigate('/signin', { replace: true });
          } else {
            setIsSubmitting(false);
            toast.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  const { values, errors, touched, handleSubmit, setFieldValue, handleBlur, getFieldProps } = formik;

  const changeHandler = (e) => {
    const { value } = e.target;
    if (onlyCharacter.test(value)) {
      setFieldValue('fullName', value);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label={t('full_name')}
            {...getFieldProps('fullName')}
            error={Boolean(touched.fullName && errors.fullName)}
            helperText={touched.fullName && errors.fullName}
            // InputProps={{
            //   pattern: "/^[a-zA-Z ]*$/"
            // }}
            value={values.fullName}
            onBlur={handleBlur}
            onChange={(e) => changeHandler(e)}
          />

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={t('email_address')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            autoComplete="username"
            type="text"
            label={t('username')}
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={t('password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={confirmShowPassword ? 'text' : 'password'}
            label={t('confirm_password')}
            {...getFieldProps('confirmpassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setConfirmShowPassword((prev) => !prev)}>
                    <Iconify icon={confirmShowPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.confirmpassword && errors.confirmpassword)}
            helperText={touched.confirmpassword && errors.confirmpassword}
          />
          <Typography sx={{ fontWeight: 'bold' }} variant="caption">
            {t('accept_terms')}
          </Typography>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            {t('register')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
