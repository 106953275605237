import React from 'react';
import { useTranslation } from 'react-i18next';
import { US, FR, ES, PT } from 'country-flag-icons/react/3x2';
import classes from './ChangeLang.module.css';

const languages = [
  { code: 'en', name: 'English', flag: 'US' },
  { code: 'fr', name: 'French', flag: 'FR' },
  { code: 'es', name: 'Spanish', flag: 'ES' },
  { code: 'pt', name: 'Portuguese', flag: 'PT' },
  // Add more languages as needed
];

const ChangeLang = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const currentLanguage = i18n.language;

  const getFlagIcon = (language) => {
    switch (language) {
      case 'en':
        return <US className={classes['flag']} />;
      case 'fr':
        return <FR className={classes['flag']} />;
      case 'es':
        return <ES className={classes['flag']} />;
      case 'pt':
        return <PT className={classes['flag']} />;
      default:
        return null;
    }
  };

  return (
    <div className={classes['language-dropdown']}>
      <div className={classes['selected-language']}>
        {getFlagIcon(currentLanguage)} {languages.find((lang) => lang.code === currentLanguage).name}
      </div>
      <ul className={classes['language-list']}>
        {languages.map(({ code, name, flag }) => (
          <li key={code} onClick={() => changeLanguage(code)}>
            {getFlagIcon(code)} {name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChangeLang;
