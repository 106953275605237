import { DataService } from '../dataService';
export const adminBannerServices = {
    getBannerApi: (data) => {
        return DataService.get("/user/banner-slider", data);
    },
    assingBannerApi: (id) => {
        return DataService.post(`/user/banner-slider/${id}`);
    },
    deleteBannerApi: (id) => {
        return DataService.delete(`/user/banner-slider/${id}`);
    },
}