import { DataService } from '../dataService';

export const userService = {
  // getUserApi: (data) => {
  //     return DataService.get("/user/manage", data)
  // },

  getUserApi: (data, sortObject) => {
    let response;

    if (Object.keys(sortObject).includes('full_name')) {
      response = DataService.get(`/user/manage?sort[${Object.keys(sortObject)[0]}]=${Object.values(sortObject)[0]}`);
    } else {
      response = DataService.get('/user/manage', data);
    }

    return response;
  },

  getUserCsv: () => {
    return DataService.get('/user/manage-csv');
  },

  getAllUserApi: (data) => {
    return DataService.get('/user/manage', data);
  },

  AddUserApi: (data) => {
    return DataService.post('/user/manage', data);
  },
  AddUserRole: (id, data) => {
    return DataService.post(`/user/role/${id}`, data);
  },
  getUserRoleApi: (id) => {
    return DataService.get(`/user/role/${id}`);
  },
  deleteUserRole: (id, data) => {
    if (Object.keys(data).length) {
      return DataService.delete(`/user/role/${id}`, data);
    } else {
      return DataService.delete(`/user/role/${id}`);
    }
  },
  deleteUserApi: (id) => {
    return DataService.delete(`/user/manage/${id}`);
  },
  detailUserApi: (id) => {
    return DataService.get(`/user/manage/${id}`);
  },
  updateUserApi: (id, data) => {
    return DataService.put(`/user/manage/${id}`, data);
  },
  updateUserStatusApi: (id, status) => {
    return DataService.patch(`/user/manage/${id}`, status);
  },
};
