import { combineReducers } from 'redux'
import { bookTypeReducer, htmlContentReducer, singleReducer, nameReducer, InstructorMainBookReducer, breadcrumbReducer, customSequenceReducer, regExpSequenceReducer, renderReducer } from './reducer'

export default combineReducers({
    authDetail: singleReducer,
    typeDetail: bookTypeReducer,
    contentHTML: htmlContentReducer,
    nameDetail: nameReducer,
    mainBookIdDetail: InstructorMainBookReducer,
    breadCrumbDetal: breadcrumbReducer,
    customArrayDetail: customSequenceReducer,
    regExpArrayDetail: regExpSequenceReducer,
    renderDetail: renderReducer
})