import { DataService } from '../dataService';

export const authServices = {
  loginAuth: (data) => {
    return DataService.post('/user/login', data);
  },
  registerReaderAuth: (data) => {
    return DataService.post('/user/register', data);
  },
  otpAuth: (data) => {
    return DataService.post('/user/authenticate', data);
  },
  sendOTPAuth: (data) => {
    return DataService.post('/user/send-otp', data);
  },
  changePasswordApi: (data) => {
    return DataService.put('/user/change-password', data);
  },
  verifyOTPAuth: (data) => {
    return DataService.post('/user/verify-otp', data);
  },
  resetPasswordApi: (data) => {
    return DataService.post('/user/forget-password', data);
  },
};
