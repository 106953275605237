import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Typography, Stack, Button } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import AuthSocial from '../auth/AuthSocial';
import { RegisterForm } from '../auth/register';
import Iconify from '../../components/Iconify';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(6, 0),
  maxWidth: '400px',
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0, 2, 2, 2),
  borderRadius: '5px',
}));

const SignUpModal = ({ open, setOpen, setLogin }) => {
  const goToLogin = () => {
    setOpen(false);
    setLogin(true);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ContentStyle>
          <SectionStyle>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography align="center" sx={{ mb: 2, mt: 2 }} variant="h4" gutterBottom>
                Join aBooks for free.
              </Typography>

              <Button onClick={() => setOpen(false)}>
                <Iconify icon="eva:close-fill" className="user-btn" width={25} height={25} />
              </Button>
            </Stack>

            <RegisterForm setLogin={setLogin} setOpen={setOpen} />

            <AuthSocial />
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                Already have an account?{' '}
                <Button
                  color="success"
                  sx={{ mb: '3px', '&:hover': { color: 'green' } }}
                  variant="text"
                  onClick={goToLogin}
                  component={RouterLink}
                >
                  Login
                </Button>
              </Typography>
            </Stack>

            {/* {!smUp && (
              <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                Already have an account?{' '}
                <Link variant="subtitle2" to="/login" component={RouterLink}>
                  Login
                </Link>
              </Typography>
            )} */}
          </SectionStyle>
        </ContentStyle>
      </Fade>
    </Modal>
  );
};

export default SignUpModal;
