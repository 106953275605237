import { DataService } from '../dataService';

export const profileService = {
  getProfileDetailApi: () => {
    return DataService.get('/user/profile-details');
  },
  profileUpdateApi: (data) => {
    return DataService.put('/user/edit-profile', data, {
      'Content-Type': 'multipart/form-data',
    });
  },
};
