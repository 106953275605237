import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import { authServices } from '../../../Services/ApiHandlers/authServices';
import Iconify from '../../../components/Iconify';
import { Storage } from 'src/Storage';
import VerifyOtp from '../VerifyOTP/VerifyOtp';
import { useDispatch } from 'react-redux';
import { userAuthAction } from 'src/Redux/Action/storageAction';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function LoginForm({ redirectURL = '/' }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [resendData, setResendData] = useState({
    email: '',
    password: '',
  });
  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      setIsSubmitting(true);
      const postData = {
        email: values.email,
        password: values.password,
      };
      authServices
        .loginAuth(postData)
        .then((response) => {
          if (response.code === 200) {
            if (response.data?.user_role) {
              toast.success('User logged in successfully.');
              dispatch(userAuthAction(JSON.stringify(response.data)));
              Storage.set('authToken', JSON.stringify(response.data));
              //navigate('/', { state: "User logged in successfully." });
              setTimeout(() => {
                window.location.href = redirectURL;
              }, 500);
              setIsSubmitting(false);
            } else {
              // dispatch(adminAuthAction(JSON.stringify(response.data)))
              toast.success(response.message);
              Storage.set('otpToken', JSON.stringify(response.data));
              setOpen(true);
              setIsSubmitting(false);
              setResendData(postData);
              setRunTimer((t) => !t);
            }
            // navigate('/dashboard/app', { replace: true });
          } else if (response.code === 400) {
            setIsSubmitting(false);
            toast.error('Login credential is invalid');
          } else if (response.code === 401) {
            setIsSubmitting(false);
            toast.error('Login credential is invalid');
          } else {
            setIsSubmitting(false);
            toast.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  /// for otp count

  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 3);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  React.useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <FormControlLabel
              control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
              label={t('remember_me')}
            />

            <Link component={RouterLink} variant="subtitle2" to="/forget-password" underline="hover">
              {t('forgot_password')}
            </Link>
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            {t('login')}
          </LoadingButton>
        </Form>
      </FormikProvider>
      <VerifyOtp
        setOpen={setOpen}
        open={open}
        minute={minutes}
        second={seconds}
        countdown={runTimer}
        setCountDown={setRunTimer}
        resendData={resendData}
        typeOfotp={'login'}
      />
    </>
  );
}
