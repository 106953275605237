import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import OtpInput from 'react-otp-input';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { authServices } from 'src/Services/ApiHandlers/authServices';
import { toast } from 'react-toastify';
import { Storage } from 'src/Storage';
// ----------------------------------------------------------------------

const SectionStyle = styled(Box)((theme) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  border: '2px solid #000',
  boxShadow: 24,
  background: '#fff',
  padding: '15px',
}));

export default function VerifyOtp({ setOpen, open, minute, second, countdown, setCountDown, resendData, typeOfotp }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = {
    OTP: null,
  };
  const validationSchema = Yup.object().shape({
    OTP: Yup.string()
      .required('OTP is required')
      .matches(/^[0-9\b]+$/, 'OTP should be number')
      .nullable(),
  });
  //// resend otp

  const resendOtpHandler = () => {
    if (typeOfotp === 'login') {
      authServices
        .loginAuth(resendData)
        .then((response) => {
          if (response.code === 200) {
            Storage.set('otpToken', JSON.stringify(response.data));
            toast.success(response.message);
            setIsSubmitting(false);
            setCountDown((t) => !t);
          } else {
            setIsSubmitting(false);
            toast.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (typeOfotp === 'forget') {
      authServices
        .sendOTPAuth(resendData)
        .then((response) => {
          if (response.code === 200) {
            Storage.set('otpToken', JSON.stringify(response.data));
            toast.success(response.message);
            setIsSubmitting(false);
            setCountDown((t) => !t);
          } else {
            setIsSubmitting(false);
            toast.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        setIsSubmitting(true);

        if (typeOfotp === 'forget') {
          const verifyData = {
            mail_otp: values.OTP,
            email: resendData?.email,
          };
          authServices
            .verifyOTPAuth(verifyData)
            .then((response) => {
              if (response.code === 200) {
                toast.success(response.message);
                Storage.set('otpToken', JSON.stringify(response.data));
                // dispatch(userAuthAction(JSON.stringify(response.data)))
                setOpen(false);
                setIsSubmitting(false);
                // navigate('/reset-password', { replace: true });
                window.location.href = '/reset-password';
              } else {
                setIsSubmitting(false);
                toast.error(response.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }

        if (typeOfotp === 'login') {
          const postData = {
            mail_otp: values.OTP,
            email: resendData?.email,
          };
          authServices
            .otpAuth(postData)
            .then((response) => {
              if (response.code === 200) {
                toast.success('You are logged in successfully');

                Storage.set('authToken', JSON.stringify(response.data));
                Storage.remove('otpToken');
                setOpen(false);
                setIsSubmitting(false);
                let location = '/dashboard/app';
                if (response.data.status === 'NOT_VERIFY') {
                  location = '/';
                }
                setTimeout(() => {
                  window.location.href = location;
                }, 500);
              } else {
                setIsSubmitting(false);
                toast.error(response.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }}
      validationSchema={validationSchema}
    >
      {(props) => {
        const { values, setFieldValue, handleChange, handleBlur, setFieldTouched, errors, touched } = props;
        return (
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Form>
              {/* {console.log(errors.OTP, touched.OTP, errors.OTP)} */}
              <Fade in={open}>
                <SectionStyle className="customBox">
                  <Typography sx={{ mb: 1, textAlign: 'center' }} variant="h6" gutterBottom>
                    Please Enter the OTP to verify your account
                  </Typography>
                  <Typography sx={{ mb: 0, textAlign: 'center' }} gutterBottom>
                    A one time password has been sent to your mail
                  </Typography>
                  <div className="d-flex justify-content-center">
                    <OtpInput
                      className="otp_section align-items-center justify-content-center"
                      value={values.OTP}
                      onChange={(event) => {
                        handleChange(event);
                        setFieldValue(`OTP`, event);
                      }}
                      onBlur={(event) => {
                        handleBlur(`OTP`, event);
                        setFieldTouched(true);
                      }}
                      numInputs={6}
                      renderSeparator={<span>-</span>}
                      inputStyle={{
                        width: '3rem',
                        height: '3rem',
                        fontSize: '1rem',
                        margin: '20px 5px',
                        borderBottom: '1px solid',
                        borderBottomRightRadius: '0px',
                        borderBottomLeftRadius: '0px',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                      }}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                  {errors.OTP && touched.OTP && (
                    <div style={{ color: 'red', margin: '0px 0 10px 25px' }}>{errors.OTP}</div>
                  )}
                  {/* 
                                    <ButtonStyle className="defaultAdminBtn" type="submit">
                                        Submit OTP
                                    </ButtonStyle> */}

                  {countdown && (
                    <span style={{ display: 'block', marginBottom: '5px', textAlign: 'center' }}>
                      <small>
                        Your OTP will be expired in{' '}
                        <strong>
                          {minute}: {second}
                        </strong>{' '}
                        minutes
                      </small>
                    </span>
                  )}

                  {countdown ? (
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                      Submit OTP
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                      loading={isSubmitting}
                      onClick={() => resendOtpHandler()}
                    >
                      Resend OTP
                    </LoadingButton>
                  )}
                </SectionStyle>
              </Fade>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}
