// @mui
import { styled } from '@mui/material/styles';
import { Typography, Button, Stack } from '@mui/material';
// hooks
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
// components
import Iconify from '../../components/Iconify';

const SectionStyle = styled(Box)((theme) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  border: '2px solid #000',
  boxShadow: 24,
  background: '#fff',
  padding: '15px',
}));

const AlignSection = styled('div')(() => ({
  width: '100%',
  textAlign: 'center',
}));

const DeleteModal = ({ open, setOpen, deleteHandler, title }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <SectionStyle className="customBox">
          <AlignSection>
            <Iconify sx={{ alignItems: 'center' }} icon="eva:trash-2-outline" color="#1877F2" width={70} height={70} />

            <Typography sx={{ mb: 0, textAlign: 'center' }} gutterBottom>
              {title ? title : 'Are you sure you want to delete ?'}
            </Typography>
          </AlignSection>

          <Stack direction="row" alignItems="center" justifyContent="center" sx={{ marginTop: 3 }}>
            <Button
              sx={{ marginRight: 2, padding: '8px 12px', height: '100%' }}
              type="button"
              variant="contained"
              color="error"
              onClick={() => deleteHandler()}
            >
              YES {/* Submit OTP */}
            </Button>
            <Button
              sx={{ marginRight: 2, padding: '8px 12px', height: '100%' }}
              type="button"
              variant="contained"
              color="success"
              onClick={() => setOpen(false)}
            >
              NO
            </Button>
          </Stack>
        </SectionStyle>
      </Fade>
    </Modal>
  );
};

export default DeleteModal;
