import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      home_main_heading_1: 'Revolutionizing Books',
      home_main_heading_2: 'Empowering Educators',
      home_main_heading_3: 'Personalizing Learning',
      home_h_1: 'Achieve More in Less Time',
      home_h_2: 'Simplified Teaching, Personalized Learning',
      home_h_3: 'For Students and Readers',
      home_description_1: `Don't you feel that reading traditional textbooks is an inefficient way of learning and that books can be written way better?`,
      home_question_1: "Don't you feel that today's books are wordy and boring?",
      home_h_4: 'Learn More Efficiently With\n Adaptive Books',
      home_h_5: 'Read the compact version of a book. Read in more detail whenever you feel the need.',
      home_description_2: 'Read the compact version of a book.',
      home_description_3: 'Read in more detail whenever you feel the need.',
      home_h_6: 'Interactive 3D Graphs',
      home_description_4: 'Is it difficult for you to picture a 3D image from a 2D image on paper?',
      home_description_5: 'If so, you will benefit from our interactive graphs.',
      home_h_7: 'For Instructors',
      home_h_8: 'Fully customize the textbook for your class.',
      home_li_1: 'Change the orders of the sections.',
      home_li_2: 'Delete the irrelevant material.',
      home_li_3: 'Edit the explanations.',
      home_li_4: 'Add or remove examples and exercises.',
      footer_h_1: 'Quick Links',
      footer_a_1: 'Become an Author',
      footer_a_2: 'Teach a Book',
      footer_a_3: 'Careers',
      footer_a_4: 'Privacy Policy',
      footer_a_terms: 'Terms and Conditions',
      footer_a_5: 'Contact Us',
      footer_a_6: 'Forum',
      footer_a_7: 'Blog',
      footer_social_media: 'Social Media',
      footer_description_1: 'Learn Most Efficiently',
      footer_description_2: 'Through Adaptive Books',
      footer_adress: '8 The Green STE A <br/> Dover, DE 19901',
      nav_a_1: 'Books',
      nav_a_2: 'My Library',
      nav_a_3: 'About Us',
      nav_a_4: 'Pricing',
      nav_a_6: 'AI Exam Booster',
      nav_a_homework: 'Smart Assignments',
      sign_in: 'Sign in',
      join_for_free: 'Join for free',
      my_profile: 'My Profile',
      admin_dashboard: 'Admin Dashboard',
      change_password: 'Change Password',
      exam_management: 'Homework Management',
      logout: 'Log out',
      login_h1: 'Enter your details below',
      login_dont_have_account: "Don't have an account?",
      get_started: 'Get started',
      dont_have_account: 'Don’t have an account?',
      sign_in_to_abook: 'Sign in to ABook',
      enter_your_details_below: 'Enter your details below.',
      forgot_password: 'Forgot Password?',
      remember_me: 'Remember me',
      login: 'Login',
      email_address: 'Email Address',
      full_name: 'Full Name',
      username: 'Username',
      password: 'Password',
      confirm_password: 'Confirm Password',
      register: 'Register',
      accept_terms: 'By clicking Register, you agree to our Terms and Privacy Policy',
      price_feature_1: 'A diverse range of books available at no cost',
      price_feature_2: 'Complete access to the entire library, including premium content.',
      price_feature_3: 'Includes everything in the Premium Access',
      price_feature_4: 'Personalized AI assistant to answer queries and provide explanations on topics',
      price_feature_5: 'Access progress tracking',
      price_feature_6: 'AI-driven Exam Booster for tailored practice and instant feedback',
      price_feature_7: 'AI Summarization: Instantly summarize selected text.',
      my_favorite_books: 'My Favorite Books',
      view: 'View',
      author_dashboard: 'Author Dashboard',
      instructor_dashboard: 'Instructor Dashboard',
      learn_from_leading_universities: 'Learn From Leading Universities',
    },
  },
  fr: {
    translation: {
      home_main_heading_1: 'Révolutionner les Livres',
      home_main_heading_2: 'Donner du pouvoir aux éducateurs',
      home_main_heading_3: 'Personnaliser l\'apprentissage',
      home_h_1: 'Accomplissez Plus en Moins de Temps',
      home_h_2: 'Un Enseignement Simplifié, Un Apprentissage Personnalisé',
      home_h_3: 'Pour les étudiants et les lecteurs',
      home_description_1: `Ne trouvez-vous pas que la lecture de manuels traditionnels est une manière inefficace d'apprendre et que les livres pourraient être écrits de manière bien meilleure ?`,
      home_question_1: "Ne trouvez-vous pas que les livres d'aujourd'hui sont verbeux et ennuyeux ?",
      home_h_4: 'Apprenez de manière plus efficace avec\n des Livres Adaptatifs',
      home_h_5: "Lisez la version condensée d'un livre. Lisez en détail chaque fois que vous en ressentez le besoin.",
      home_description_2: "Lisez la version condensée d'un livre.",
      home_description_3: 'Lisez en détail chaque fois que vous en ressentez le besoin.',
      home_h_6: 'Graphiques interactifs en 3D',
      home_description_4: "Vous est-il difficile de visualiser une image en 3D à partir d'une image en 2D sur papier ?",
      home_description_5: "Si c'est le cas, vous bénéficierez de nos graphiques interactifs.",
      home_h_7: 'Pour les instructeurs',
      home_h_8: 'Personnalisez entièrement le manuel pour votre classe.',
      home_li_1: "Changez l'ordre des sections.",
      home_li_2: 'Supprimez le matériel non pertinent.',
      home_li_3: 'Modifiez les explications.',
      home_li_4: 'Ajoutez ou supprimez des exemples et des exercices.',
      footer_h_1: 'Liens rapides',
      footer_a_1: 'Devenir Auteur',
      footer_a_2: 'Enseigner un Livre',
      footer_a_3: 'Carrières',
      footer_a_4: 'Politique de confidentialité',
      footer_a_5: 'Contactez-nous',
      footer_a_6: 'Forum',
      footer_a_7: 'Blog',
      footer_social_media: 'Médias sociaux',
      footer_description_1: 'Apprenez de manière la plus efficace',
      footer_description_2: 'Grâce aux Livres Adaptatifs',
      footer_adress: '8 The Green STE A <br/> Dover, DE 19901',
      nav_a_1: 'Livres',
      nav_a_2: 'Ma Bibliothèque',
      nav_a_3: 'À Propos de Nous',
      nav_a_4: 'Tarification',
      nav_a_5: 'Exam Booster',
      nav_a_homework: 'Devoirs',
      sign_in: 'Se connecter',
      join_for_free: 'Rejoindre gratuitement',
      my_profile: 'Mon Profil',
      admin_dashboard: 'Tableau de bord administrateur',
      change_password: 'Changer le mot de passe',
      exam_management: 'Gestion des examens',
      logout: 'Déconnexion',
      login_h1: 'Saisissez vos coordonnées ci-dessous',
      login_dont_have_account: "Vous n'avez pas de compte ?",
      get_started: 'Commencer',
      dont_have_account: "Vous n'avez pas de compte ?",
      sign_in_to_abook: 'Se connecter à ABook',
      enter_your_details_below: 'Saisissez vos coordonnées ci-dessous.',
      forgot_password: 'Mot de passe oublié ?',
      remember_me: 'Souviens-toi de moi',
      login: 'Se connecter',
      email_address: 'Adresse e-mail',
      full_name: 'Nom complet',
      username: "Nom d'utilisateur",
      password: 'Mot de passe',
      confirm_password: 'Confirmer le mot de passe',
      register: "S'inscrire",
      accept_terms: "En cliquant sur S'inscrire, vous acceptez nos conditions et notre politique de confidentialité",
      price_feature_1: 'Une gamme diversifiée de livres disponibles gratuitement',
      price_feature_2: 'Accès complet à toute la bibliothèque, y compris le contenu premium.',
      price_feature_3: "Comprend tout dans l'Avenue Académique",
      price_feature_4:
        'Assistant IA personnalisé pour répondre aux questions et fournir des explications sur les sujets',
      my_favorite_books: 'Mes Livres Préférés',
      view: 'Voir',
      author_dashboard: 'Tableau de bord de l’auteur',
      instructor_dashboard: 'Tableau de bord de l’instructeur',
      learn_from_leading_universities: 'Apprendre des universités de premier plan',
    },
  },
  es: {
    translation: {
      home_main_heading_1: 'Revolucionando los libros',
      home_main_heading_2: 'Empoderando a los educadores',
      home_main_heading_3: 'Personalizando el aprendizaje',
      home_h_1: 'Logra Más en Menos Tiempo',
      home_h_2: 'Enseñanza Eficiente, Aprendizaje Personalizado',
      home_h_3: 'Para estudiantes y lectores',
      home_description_1: `¿No sientes que leer libros de texto tradicionales es una forma ineficiente de aprender y que los libros podrían escribirse de una manera mucho mejor?`,
      home_question_1: '¿No sientes que los libros de hoy son demasiado verbosos y aburridos?',
      home_h_4: 'Aprende de manera más eficiente con\n Libros Adaptativos',
      home_h_5: 'Lee la versión compacta de un libro. Lee en más detalle siempre que lo necesites.',
      home_description_2: 'Lee la versión compacta de un libro.',
      home_description_3: 'Lee en más detalle siempre que lo necesites.',
      home_h_6: 'Gráficos interactivos en 3D',
      home_description_4: '¿Te resulta difícil imaginar una imagen en 3D a partir de una imagen en 2D en papel?',
      home_description_5: 'Si es así, te beneficiarás de nuestros gráficos interactivos.',
      home_h_7: 'Para instructores',
      home_h_8: 'Personaliza completamente el libro de texto para tu clase.',
      home_li_1: 'Cambia el orden de las secciones.',
      home_li_2: 'Elimina el material no relevante.',
      home_li_3: 'Edita las explicaciones.',
      home_li_4: 'Añade o elimina ejemplos y ejercicios.',
      footer_h_1: 'Enlaces rápidos',
      footer_a_1: 'Conviértete en autor',
      footer_a_2: 'Enseña un libro',
      footer_a_3: 'Empleos',
      footer_a_4: 'Política de privacidad',
      footer_a_5: 'Contáctanos',
      footer_a_6: 'Foro',
      footer_a_7: 'Blog',
      footer_social_media: 'Redes sociales',
      footer_description_1: 'Aprende de manera más eficiente',
      footer_description_2: 'A través de Libros Adaptativos',
      footer_adress: '8 The Green STE A <br/> Dover, DE 19901',
      nav_a_1: 'Libros',
      nav_a_2: 'Mi Biblioteca',
      nav_a_3: 'Acerca de nosotros',
      nav_a_4: 'Precios',
      nav_a_5: 'Booster de exámenes',
      nav_a_homework: 'Tarea',
      sign_in: 'Iniciar sesión',
      join_for_free: 'Únete gratis',
      my_profile: 'Mi perfil',
      admin_dashboard: 'Panel de administración',
      change_password: 'Cambiar contraseña',
      logout: 'Cerrar sesión',
      login_h1: 'Ingresa tus datos a continuación',
      login_dont_have_account: '¿No tienes una cuenta?',
      get_started: 'Empezar',
      dont_have_account: '¿No tienes una cuenta?',
      sign_in_to_abook: 'Iniciar sesión en ABook',
      enter_your_details_below: 'Ingresa tus datos a continuación.',
      forgot_password: '¿Olvidaste tu contraseña?',
      remember_me: 'Recuérdame',
      login: 'Iniciar sesión',
      email_address: 'Dirección de correo electrónico',
      full_name: 'Nombre completo',
      username: 'Nombre de usuario',
      password: 'Contraseña',
      confirm_password: 'Confirmar contraseña',
      exam_management: 'Gestión de exámenes',
      register: 'Registrarse',
      accept_terms: 'Al hacer clic en Registrarse, aceptas nuestros Términos y Política de privacidad',
      price_feature_1: 'Una amplia gama de libros disponibles sin costo alguno',
      price_feature_2: 'Acceso completo a toda la biblioteca, incluido contenido premium.',
      price_feature_3: 'Incluye todo en la Avenida Académica',
      price_feature_4:
        'Asistente de IA personalizado para responder preguntas y proporcionar explicaciones sobre temas',
      my_favorite_books: 'Mis libros favoritos',
      view: 'Ver',
      author_dashboard: 'Panel de autor',
      instructor_dashboard: 'Panel de instructor',
      learn_from_leading_universities: 'Aprender de universidades de primer plano',
    },
  },
  pt: {
    translation: {
      home_main_heading_1: 'Revolucionando os livros',
      home_main_heading_2: 'Empoderando os educadores',
      home_main_heading_3: 'Personalizando o aprendizado',
      home_h_1: 'Alcance Mais em Menos Tempo',
      home_h_2: 'Ensino Eficiente, Aprendizado Personalizado',
      home_h_3: 'Para Estudantes e Leitores',
      home_description_1: `Você não acha que ler livros didáticos tradicionais é uma maneira ineficiente de aprender e que os livros podem ser escritos de maneira melhor?`,
      home_question_1: 'Você não acha que os livros de hoje são prolixos e entediantes?',
      home_h_4: 'Aprenda de forma mais eficiente com\n Livros Adaptativos',
      home_h_5: 'Leia a versão compacta de um livro. Leia com mais detalhes sempre que sentir necessidade.',
      home_description_2: 'Leia a versão compacta de um livro.',
      home_description_3: 'Leia com mais detalhes sempre que sentir necessidade.',
      home_h_6: 'Gráficos Interativos em 3D',
      home_description_4: 'É difícil para você visualizar uma imagem em 3D a partir de uma imagem em 2D no papel?',
      home_description_5: 'Se sim, você se beneficiará de nossos gráficos interativos.',
      home_h_7: 'Para Instrutores',
      home_h_8: 'Personalize totalmente o livro didático para a sua classe.',
      home_li_1: 'Mude a ordem das seções.',
      home_li_2: 'Exclua o material irrelevante.',
      home_li_3: 'Edite as explicações.',
      home_li_4: 'Adicione ou remova exemplos e exercícios.',
      footer_h_1: 'Links Rápidos',
      footer_a_1: 'Torne-se um Autor',
      footer_a_2: 'Ensine um Livro',
      footer_a_3: 'Carreiras',
      footer_a_4: 'Política de Privacidade',
      footer_a_5: 'Entre em Contato',
      footer_a_6: 'Fórum',
      footer_a_7: 'Blog',
      footer_social_media: 'Mídias Sociais',
      footer_description_1: 'Aprenda de Forma Mais Eficiente',
      footer_description_2: 'Através de Livros Adaptativos',
      footer_adress: '8 The Green STE A <br/> Dover, DE 19901',
      nav_a_1: 'Livros',
      nav_a_2: 'Minha Biblioteca',
      nav_a_3: 'Sobre Nós',
      nav_a_4: 'Preços',
      nav_a_5: 'Booster de Exames',
      nav_a_homework: 'Tarefa',
      sign_in: 'Entrar',
      join_for_free: 'Junte-se gratuitamente',
      my_profile: 'Meu Perfil',
      admin_dashboard: 'Painel de Administração',
      change_password: 'Alterar Senha',
      exam_management: 'Gestão de Exames',
      logout: 'Sair',
      login_h1: 'Digite seus detalhes abaixo',
      login_dont_have_account: 'Não tem uma conta?',
      get_started: 'Começar',
      dont_have_account: 'Não tem uma conta?',
      sign_in_to_abook: 'Entrar no ABook',
      enter_your_details_below: 'Digite seus detalhes abaixo.',
      forgot_password: 'Esqueceu a senha?',
      remember_me: 'Lembrar-me',
      login: 'Entrar',
      email_address: 'Endereço de E-mail',
      full_name: 'Nome Completo',
      username: 'Nome de Usuário',
      password: 'Senha',
      confirm_password: 'Confirmar Senha',
      register: 'Registrar',
      accept_terms: 'Ao clicar em Registrar, você concorda com nossos Termos e Política de Privacidade',
      price_feature_1: 'Uma variedade de livros disponíveis sem custo',
      price_feature_2: 'Acesso completo à biblioteca inteira, incluindo conteúdo premium.',
      price_feature_3: 'Inclui tudo na Avenida Acadêmica',
      price_feature_4: 'Assistente de IA personalizado para responder a perguntas e fornecer explicações sobre tópicos',
      my_favorite_books: 'Meus Livros Favoritos',
      view: 'Ver',
      author_dashboard: 'Painel do Autor',
      instructor_dashboard: 'Painel do Instrutor',
      learn_from_leading_universities: 'Aprender de universidades de primeiro plano',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
