import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//

import { lazy, useEffect } from 'react';
import { Storage } from 'src/Storage';
import UserOnlyLayout from './layouts/UserOnlyLayout';
import BannerSlideList from './pages/BannerSlider/BannerSlideList';
import Home from './pages/Home/Home';
import NotFound from './pages/Page404';
import { websiteVisitService } from './Services/ApiHandlers/websiteVisitService';

// ----------------------------------------------------------------------
const HowItWorkManagment = lazy(() => import('./pages/HowItWorkManagment/HowItWorkManagment'));
const DashboardApp = lazy(() => import('./pages/DashboardApp'));
const UpdateBlog = lazy(() => import('./pages/Blog/UpdateBlog'));
const Adapbook = lazy(() => import('./website/webpages/Adapbooks'));
const User = lazy(() => import('./pages/UsersManagement/User'));
const BlogDetail = lazy(() => import('./pages/Blog/BlogDetail'));
const HeaderView = lazy(() => import('./website/webpages/Mylibrary/HtmlContent/HeaderContent/HeaderView'));
const ForumAnswer = lazy(() => import('./website/webpages/Forum/ForumAnswer'));
const WebContact = lazy(() => import('./website/webpages/WebContact'));
const ReplaySiteForm = lazy(() => import('./pages/SiteForms/ReplaySiteForms'));
const SiteForms = lazy(() => import('./pages/SiteForms/SiteForms'));
const ReplayCareer = lazy(() => import('./pages/Career/ReplayCareer'));
const Team = lazy(() => import('./website/webpages/Team'));
const AddUser = lazy(() => import('./pages/UsersManagement/AddUser'));
const UpdateUser = lazy(() => import('./pages/UsersManagement/UpdateUser'));
const Contact = lazy(() => import('./pages/ContactUs/Contact'));
const Library = lazy(() => import('./website/webpages/Mylibrary/Library'));
const AddManagement = lazy(() => import('./pages/BooksManagement/AddBooksMangement/AddManagement'));
const ReplyContact = lazy(() => import('./pages/ContactUs/ReplyContact'));
const BooksCategory = lazy(() => import('./pages/BooksManagement/NewBooks'));
const BookContent = lazy(() => import('./pages/BooksManagement/BookContent'));
const BooksManagement = lazy(() => import('./pages/BooksManagement/BooksManagement'));
const CloneBooksManagement = lazy(() => import('./pages/BooksManagement/CloneBooksManagment'));
const UpdateBookManagement = lazy(() => import('./pages/BooksManagement/AddBooksMangement/UpdateBookManagement'));
const Becomeanauthor = lazy(() => import('./website/webpages/Becomeanauthor'));
const CurrentJobOpening = lazy(() => import('./website/webpages/CurrentJobOpening'));
const Teachabook = lazy(() => import('./website/webpages/Teachabook'));
const ForgetPass = lazy(() => import('./pages/ForgetPass'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const LibraryDetailNew = lazy(() => import('./website/webpages/Mylibrary/LibraryDetailNew'));
const WebBlog = lazy(() => import('./website/webpages/WebBlog'));
const ChangeMyPassword = lazy(() => import('./pages/ChangeMyPassword'));
const ChapterView = lazy(() => import('./website/webpages/Mylibrary/HtmlContent/ChapterView'));
const Profile = lazy(() => import('./website/webpages/Profile'));
const UpdateProfile = lazy(() => import('./website/webpages/UpdateProfile'));
const WebNewForum = lazy(() => import('./website/webpages/Forum/WebNewForum'));
const WebBlogDetail = lazy(() => import('./website/webpages/WebBlogDetail'));
const MyProfile = lazy(() => import('./pages/Profile/MyProfile'));
const AddAbout = lazy(() => import('./pages/AboutUs/AddAbout'));
const UpdateAbout = lazy(() => import('./pages/AboutUs/UpdateAbout'));
const Faq = lazy(() => import('./pages/FAQ/Faq'));
const AddFaq = lazy(() => import('./pages/FAQ/AddFaq'));
const FaqUpdate = lazy(() => import('./pages/FAQ/FaqUpdate'));
const AddBooks = lazy(() => import('./pages/BooksManagement/Books/AddBooks'));
const UpdateBooks = lazy(() => import('./pages/BooksManagement/Books/UpdateBooks'));
const Career = lazy(() => import('./pages/Career/Career'));
const BlogCategory = lazy(() => import('./pages/BlogCategory/BlogCategory'));
const AddBlogCategory = lazy(() => import('./pages/BlogCategory/AddBlogCategory'));
const Blog = lazy(() => import('./pages/Blog/Blog'));
const AddBlog = lazy(() => import('./pages/Blog/AddBlog'));
const About = lazy(() => import('./pages/AboutUs/About'));
const WebAbout = lazy(() => import('./website/webpages/WebAbout'));
const PaymentGateway = lazy(() => import('./website/webpages/PaymentMethod/PaymentGateway'));
const Pricing = lazy(() => import('./website/webpages/Pricing/Pricing'));
const SubscriptionSuccess = lazy(() => import('./website/webpages/Pricing/SubscriptionSuccess'));
const SubscriptionCancel = lazy(() => import('./website/webpages/Pricing/SubscriptionCancel'));
const WhyAdap = lazy(() => import('./website/webpages/WhyAdap'));
const SubscriptionManagment = lazy(() => import('./pages/SubscriptionManagment/SubscriptionManagment'));
const SubscriptionManagmentAdd = lazy(() => import('./pages/SubscriptionManagment/SubscriptionManagmentAdd'));
const ExamBooster = lazy(() => import('./website/webpages/ExamBooster'));
const ExamBoosterList = lazy(() => import('./website/webpages/ExamBooster/ExamBoosterList'));
const ExamsList = lazy(() => import('./website/webpages/ExamBooster/ExamsList'));
const ExamQuestion = lazy(() => import('./website/webpages/ExamBooster/ExamQuestion'));
const ExamArticleList = lazy(() => import('./website/webpages/ExamBooster/ExamArticleList'));
const ExamsManagementList = lazy(() => import('./pages/ExamsManagement/ExamsManagementList'));
const ExamCategoryManagement = lazy(() => import('./pages/ExamsManagement/ExamCategoryManagment'));
const ExamCategoryUsersResult = lazy(() =>
  import('./pages/ExamsManagement/ExamCategoryUsersResult/ExamCategoryUsersResult')
);
const ExamCategoryUsersResultExams = lazy(() =>
  import('./pages/ExamsManagement/ExamCategoryUsersResult/ExamCategoryUsersResultExams')
);
const ExamCategoryUsersResultDetail = lazy(() =>
  import('./pages/ExamsManagement/ExamCategoryUsersResult/ExamCategoryUsersResultDetail')
);
const ExamCategoryManagmentAdd = lazy(() => import('./pages/ExamsManagement/ExamCategoryManagmentAdd'));
const ExamCategoryManagementEdit = lazy(() => import('./pages/ExamsManagement/ExamCategoryManagementEdit'));
const ExamArticleManagmentAdd = lazy(() => import('./pages/ExamsManagement/ExamArticleManagmentAdd'));
const ExamArticleManagmentEdit = lazy(() => import('./pages/ExamsManagement/ExamArticleManagmentEdit'));

const ExamManuallManagement = lazy(() => import('./pages/ExamsManagement/ExamManuallManagment'));
const ExamManuallManagementAdd = lazy(() => import('./pages/ExamsManagement/ExamManuallManagmentAdd'));
const ExamManuallManagementEdit = lazy(() => import('./pages/ExamsManagement/ExamManuallManagmentEdit'));

// Incstrouctor exams route
const InstructorExamManagmentRoot = lazy(() => import('./pages/InstructorExamsManagment/InstructorExamManagmentRoot'));
const InstructorExamArticleManagmentList = lazy(() =>
  import('./pages/InstructorExamsManagment/InstructorExamArticleManagmentList')
);
const InstructorExamArticleManagmentAdd = lazy(() =>
  import('./pages/InstructorExamsManagment/InstructorExamArticleManagmentAdd')
);

const InstructorExamArticleManagmentEdit = lazy(() =>
  import('./pages/InstructorExamsManagment/InstructorExamArticleManagmentEdit')
);

const InstructorExamCategoryManagment = lazy(() =>
  import('./pages/InstructorExamsManagment/InstructorExamCategoryManagment')
);

const InstructorExamCategoryManagmentAdd = lazy(() =>
  import('./pages/InstructorExamsManagment/InstructorExamCategoryManagmentAdd')
);

const InstructorExamCategoryManagmentEdit = lazy(() =>
  import('./pages/InstructorExamsManagment/InstructorExamCategoryManagmentEdit')
);

const InstructorExamManuallManagment = lazy(() =>
  import('./pages/InstructorExamsManagment/InstructorExamManuallManagment')
);

const InstructorExamManuallManagmentAdd = lazy(() =>
  import('./pages/InstructorExamsManagment/InstructorExamManuallManagmentAdd')
);

const InstructorExamManuallManagmentEdit = lazy(() =>
  import('./pages/InstructorExamsManagment/InstructorExamManuallManagmentEdit')
);

const InstructorExamCategoryUsersResult = lazy(() =>
  import('./pages/InstructorExamsManagment/InstructorExamCategoryUsersResult/InstructorExamCategoryUsersResult')
);

const InstructorExamCategoryUsersResultExams = lazy(() =>
  import('./pages/InstructorExamsManagment/InstructorExamCategoryUsersResult/InstructorExamCategoryUsersResultExams')
);

const InstructorExamCategoryUsersResultDetail = lazy(() =>
  import('./pages/InstructorExamsManagment/InstructorExamCategoryUsersResult/InstructorExamCategoryUsersResultDetail')
);

const ExamCategoryUserResultFeedback = lazy(() =>
  import('./pages/ExamsManagement/ExamCategoryUsersResult/ExamCategoryUserResultFeedback')
);

const AllUserExams = lazy(() => import('./pages/ExamsManagement/ExamCategoryUsersResult/AllUserExams'));

// Student exam routes
const StudentExam = lazy(() => import('./website/webpages/StudentExam'));
const StudentExamArticleList = lazy(() => import('./website/webpages/StudentExam/StudentExamArticleList'));
const StudentExamCategoryList = lazy(() => import('./website/webpages/StudentExam/StudentExamCategoryList'));
const StudentExamsList = lazy(() => import('./website/webpages/StudentExam/StudentExamsList'));
const StudentExamQuestion = lazy(() => import('./website/webpages/StudentExam/StudentExamQuestion'));
const HowItWork = lazy(() => import('./pages/HowItWork/HowItWork'));

const AuthorDashboard = lazy(() => import('./layouts/AuthorDashboard/AuthorDashboard'));
const AuthorBookList = lazy(() => import('./layouts/AuthorDashboard/AuthorBookList'));
const AuthorBookInstruction = lazy(() =>
  import('./layouts/AuthorDashboard/AuthorBookInstruction/AuthorBookInstruction')
);

const AuthorBookNewInstruction = lazy(() =>
  import('./layouts/AuthorDashboard/AuthorBookNewInstruction/AuthorBookNewInstruction')
);

const InstrcutorDashboard = lazy(() => import('./layouts/InstrcutorDashboard/InstrcutorDashboard'));

const IncstrouctorBookList = lazy(() => import('./layouts/InstrcutorDashboard/InstructorBookList'));

const InstructorBookInstruction = lazy(() =>
  import('./layouts/InstrcutorDashboard/InstructorBookInstruction/InstructorBookInstruction')
);

const WebsiteVisit = lazy(() => import('./pages/WebsiteVisit/WebsiteVisit'));

const BuySub = lazy(() => import('./components/BuySub/BuySub'));

export default function Router() {
  // current path
  const { pathname } = useLocation();
  const isLoggedIn = Storage.get('authToken') && JSON.parse(Storage.get('authToken'));
  const isCredential = Storage.get('otpToken') && JSON.parse(Storage.get('otpToken'));
  const isUserRole = isLoggedIn?.user_role?.includes('SUPER_ADMIN');
  const isAuthor = isLoggedIn?.user_role?.includes('AUTHOR') || isUserRole;
  const isInstructor = isLoggedIn?.user_role?.includes('INSTRUCTOR') || isUserRole;

  useEffect(() => {
    const excludedPaths = ['/dashboard', '/library'];
    if (excludedPaths.some((excludedPath) => pathname.includes(excludedPath))) {
      return;
    }
    websiteVisitService.create({ path: pathname });
  }, [pathname]);

  let routeArray = [];
  if (isUserRole) {
    routeArray.push({
      path: '/dashboard',
      element: isUserRole ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user-management', element: <User /> },
        { path: 'user-management/add-user-management', element: <AddUser /> },
        { path: 'user-management/edit-user-management/:id', element: <UpdateUser /> },
        {
          path: 'user-management/exams/:userId',
          element: <AllUserExams />,
        },
        { path: 'about-us', element: <About /> },
        { path: 'my-profile', element: <MyProfile /> },
        { path: 'about-us/add-about', element: <AddAbout /> },
        { path: 'about-us/edit-about/:id', element: <UpdateAbout /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'career', element: <Career /> },
        { path: 'career/reply-career/:id', element: <ReplayCareer /> },
        { path: 'site-forms/:type', element: <SiteForms /> },
        { path: 'site-forms/:type/reply/:id', element: <ReplaySiteForm /> },
        { path: 'contact-us/reply-contact/:id', element: <ReplyContact /> },
        { path: 'books-category', element: <BooksManagement /> },
        { path: 'clone-books', element: <CloneBooksManagement /> },
        { path: 'books', element: <BooksCategory /> },
        { path: 'books/:slug/content', element: <BookContent /> },
        {
          path: 'exam-booster',
          element: <ExamBooster />,
          children: [
            { index: true, element: <ExamArticleList /> },
            { path: 'article/:articleId', element: <ExamBoosterList /> },
            {
              path: 'exams/:categoryId',
              element: <ExamsList />,
            },
            {
              path: 'exam/:examId',
              element: <ExamQuestion />,
            },
          ],
        },
        {
          path: 'instructor-exams',
          element: <InstructorExamManagmentRoot />,
          children: [
            { index: true, element: <InstructorExamArticleManagmentList /> },
            { path: 'new', element: <InstructorExamArticleManagmentAdd /> },
            { path: 'edit/:articleId', element: <InstructorExamArticleManagmentEdit /> },
            { path: ':articleId/categories', element: <InstructorExamCategoryManagment /> },
            { path: ':articleId/categories/new', element: <InstructorExamCategoryManagmentAdd /> },
            { path: ':articleId/categories/:categoryId', element: <InstructorExamCategoryManagmentEdit /> },
            { path: 'manuall/:articleId/:categoryId', element: <InstructorExamManuallManagment /> },
            { path: 'manuall/:articleId/:categoryId/new', element: <InstructorExamManuallManagmentAdd /> },
            { path: 'manuall/:articleId/:categoryId/:examId', element: <InstructorExamManuallManagmentEdit /> },
            {
              path: 'users-result/:articleId/:categoryId/:userId',
              element: <InstructorExamCategoryUsersResultExams />,
            },
            { path: 'users-result/:articleId/:categoryId', element: <InstructorExamCategoryUsersResult /> },
            {
              path: 'users-result/:articleId/:categoryId/:userId/:examId',
              element: <InstructorExamCategoryUsersResultDetail />,
            },
            {
              path: 'feedback/:articleId/:categoryId/:userId',
              element: <ExamCategoryUserResultFeedback />,
            },
          ],
        },
        { path: 'books/add-books', element: <AddBooks /> },
        { path: 'books/update-books/:id', element: <UpdateBooks /> },
        { path: 'books-category/edit-book-management/:id', element: <UpdateBookManagement /> },
        { path: 'books-category/add-book-management', element: <AddManagement /> },
        { path: 'subscriptions', element: <SubscriptionManagment /> },
        { path: 'subscriptions/add', element: <SubscriptionManagmentAdd /> },
        { path: 'exams-management', element: <ExamsManagementList /> },
        { path: 'exams-management/new', element: <ExamArticleManagmentAdd /> },
        { path: 'exams-management/manuall/:articleId/:categoryId', element: <ExamManuallManagement /> },
        { path: 'exams-management/manuall/:articleId/:categoryId/new', element: <ExamManuallManagementAdd /> },
        { path: 'exams-management/manuall/:articleId/:categoryId/:examId', element: <ExamManuallManagementEdit /> },
        { path: 'exams-management/edit/:articleId', element: <ExamArticleManagmentEdit /> },
        { path: 'exams-management/:articleId/categories', element: <ExamCategoryManagement /> },
        { path: 'exams-management/users-result/:articleId/:categoryId', element: <ExamCategoryUsersResult /> },
        {
          path: 'exams-management/feedback/:articleId/:categoryId/:userId',
          element: <ExamCategoryUserResultFeedback />,
        },
        {
          path: 'exams-management/users-result/:articleId/:categoryId/:userId',
          element: <ExamCategoryUsersResultExams />,
        },
        {
          path: 'exams-management/users-result/:articleId/:categoryId/:userId/:examId',
          element: <ExamCategoryUsersResultDetail />,
        },
        { path: 'exams-management/:articleId/categories/new', element: <ExamCategoryManagmentAdd /> },
        { path: 'exams-management/:articleId/categories/:categoryId', element: <ExamCategoryManagementEdit /> },

        { path: 'subscription', element: <NotFound /> },
        { path: 'banner-slider', element: <BannerSlideList /> },
        { path: 'how-it-work', element: <HowItWorkManagment /> },
        { path: 'my-blogs', element: <Blog /> },
        { path: 'blog-category', element: <BlogCategory /> },
        { path: 'blog-category/add-blog-category', element: <AddBlogCategory /> },
        { path: 'my-blogs/add-blog', element: <AddBlog /> },
        { path: 'my-blogs/blog-edit/:id', element: <UpdateBlog /> },
        { path: 'my-blogs/blog-detail/:id', element: <BlogDetail /> },
        { path: 'faq', element: <Faq /> },
        { path: 'website-visits', element: <WebsiteVisit /> },
        { path: 'careers', element: <CurrentJobOpening /> },
        { path: 'faq/addfaq', element: <AddFaq /> },
        { path: 'faq/faqupdate/:id', element: <FaqUpdate /> },
        { path: 'detail/:id', element: <LibraryDetailNew /> },
        // { path: '*', element: <Navigate to="/" /> },
      ],
    });
  }

  if (isAuthor || isUserRole) {
    routeArray.push({
      path: '/author-dashboard',
      element: isAuthor ? <AuthorDashboard /> : <Navigate to="/" />,
      children: [
        { index: true, element: <AuthorBookList /> },
        {
          path: 'instructions/:book_id',
          element: <AuthorBookInstruction />,
        },
        {
          path: 'instructions/:book_id/add',
          element: <AuthorBookNewInstruction />,
        },
      ],
    });
  }

  if (isInstructor) {
    routeArray.push({
      path: '/instructor-dashboard',
      element: isInstructor ? <InstrcutorDashboard /> : <Navigate to="/" />,
      children: [
        { index: true, element: <IncstrouctorBookList /> },
        {
          path: 'instructions/:book_id',
          element: <InstructorBookInstruction />,
        },
      ],
    });
  }

  if (isLoggedIn) {
    routeArray.push({
      path: '/',
      element: isLoggedIn ? <UserOnlyLayout /> : <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Home /> },
        { path: 'login', element: <Home /> },
        { path: 'about', element: <WebAbout /> },
        { path: 'contact', element: <WebContact /> },
        { path: 'my-profile', element: <Profile /> },
        { path: 'edit-profile', element: <UpdateProfile /> },
        { path: 'become-an-author', element: <Becomeanauthor /> },
        { path: 'subscription/success', element: <SubscriptionSuccess /> },
        { path: 'subscription/cancel', element: <SubscriptionCancel /> },
        { path: 'teach-a-book', element: <Teachabook /> },
        { path: 'blog', element: <WebBlog /> },
        { path: 'blog/blog-detail/:id', element: <WebBlogDetail /> },
        // { path: 'forum', element: <WebForum /> },
        { path: 'forum-new', element: <WebNewForum /> },
        { path: 'forum', element: <WebNewForum /> },
        { path: 'forum-ans/:q_id', element: <ForumAnswer /> },
        { path: 'library', element: <Adapbook /> },
        { path: 'change-password', element: <ChangeMyPassword /> },
        { path: 'team', element: <Team /> },
        { path: '*', element: <NotFound /> },
        { path: 'payment', element: <PaymentGateway /> },
        { path: 'blog/add-blog', element: <AddBlog /> },
        { path: 'blog/blog-edit/:id', element: <UpdateBlog /> },
        {
          path: 'exam-booster',
          element: <ExamBooster />,
          children: [
            { index: true, element: <ExamArticleList /> },
            { path: 'article/:articleId', element: <ExamBoosterList /> },
            {
              path: 'exams/:categoryId',
              element: <ExamsList />,
            },
            {
              path: 'exam/:examId',
              element: <ExamQuestion />,
            },
          ],
        },
        {
          path: 'smart-assignments',
          element: <StudentExam />,
          children: [
            { index: true, element: <StudentExamArticleList /> },
            { path: 'article/:articleId', element: <StudentExamCategoryList /> },
            { path: 'exams/:categoryId', element: <StudentExamsList /> },
            { path: 'exam/:examId', element: <StudentExamQuestion /> },
          ],
        },
        {
          path: 'instructor-exams',
          element: <InstructorExamManagmentRoot />,
          children: [
            { index: true, element: <InstructorExamArticleManagmentList /> },
            { path: 'new', element: <InstructorExamArticleManagmentAdd /> },
            { path: 'edit/:articleId', element: <InstructorExamArticleManagmentEdit /> },
            { path: ':articleId/categories', element: <InstructorExamCategoryManagment /> },
            { path: ':articleId/categories/new', element: <InstructorExamCategoryManagmentAdd /> },
            { path: ':articleId/categories/:categoryId', element: <InstructorExamCategoryManagmentEdit /> },
            { path: 'manuall/:articleId/:categoryId', element: <InstructorExamManuallManagment /> },
            { path: 'manuall/:articleId/:categoryId/new', element: <InstructorExamManuallManagmentAdd /> },
            { path: 'manuall/:articleId/:categoryId/:examId', element: <InstructorExamManuallManagmentEdit /> },
            {
              path: 'users-result/:articleId/:categoryId/:userId',
              element: <InstructorExamCategoryUsersResultExams />,
            },
            { path: 'users-result/:articleId/:categoryId', element: <InstructorExamCategoryUsersResult /> },
            {
              path: 'users-result/:articleId/:categoryId/:userId/:examId',
              element: <InstructorExamCategoryUsersResultDetail />,
            },
            {
              path: 'feedback/:articleId/:categoryId/:userId',
              element: <ExamCategoryUserResultFeedback />,
            },
          ],
        },
        { path: 'my-library', element: <Library /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'library/:id', element: <LibraryDetailNew /> },
        { path: 'forum', element: <WebNewForum /> },
        { path: 'forum-ans/:q_id', element: <ForumAnswer /> },
        { path: 'payment', element: <PaymentGateway /> },
        { path: 'team', element: <Team /> },
        { path: 'change-password', element: <ChangeMyPassword /> },
        { path: 'about-us', element: <WhyAdap /> },
        { path: 'how-it-works-0601', element: <HowItWork /> },
        /// author book chapter and header routes
        { path: 'library/:id/:chapter', element: <ChapterView /> },
        { path: '/library/:id/:chapter/:header', element: <HeaderView /> },
        { path: '/library/:id/:chapter/:header/:headertwo', element: <HeaderView /> },
        { path: '/library/:id/:chapter/:header/:headertwo/:headerthree', element: <HeaderView /> },
        { path: 'careers', element: <CurrentJobOpening /> },
        // { path: '*', element: <Navigate to="/" /> },

        {
          path: 'need-sub',
          element: <BuySub />,
        },
      ],
    });
  }

  routeArray.push({
    path: '/',
    element: !isLoggedIn ? <LogoOnlyLayout /> : <Navigate to="/" />,
    children: [
      { path: '/', element: <Home /> },
      // { path: 'forum', element: <WebForum /> },
      { path: 'forum', element: <WebNewForum /> },
      { path: 'forum-ans/:q_id', element: <ForumAnswer /> },
      { path: 'forget-password', element: <ForgetPass /> },
      { path: 'about-us', element: <WhyAdap /> },
      { path: 'how-it-works-0601', element: <HowItWork /> },
      { path: 'contact', element: <WebContact /> },
      { path: 'reset-password', element: isCredential ? <ResetPassword /> : <Navigate to="/signin" /> },
      { path: 'blog', element: <WebBlog /> },
      { path: 'blog/blog-detail/:id', element: <WebBlogDetail /> },
      { path: 'become-an-author', element: <Becomeanauthor /> },
      { path: 'teach-a-book', element: <Teachabook /> },
      { path: 'team', element: <Team /> },
      { path: '404', element: <NotFound /> },
      { path: 'library', element: <Adapbook /> },
      { path: 'careers', element: <CurrentJobOpening /> },
      {
        path: 'exam-booster',
        element: <ExamBooster />,
        children: [
          { index: true, element: <ExamArticleList /> },
          { path: 'article/:articleId', element: <ExamBoosterList /> },
          {
            path: 'exams/:categoryId',
            element: <ExamsList />,
          },
          {
            path: 'exam/:examId',
            element: <ExamQuestion />,
          },
        ],
      },
      {
        path: 'smart-assignments',
        element: <StudentExam />,
        children: [
          { index: true, element: <StudentExamArticleList /> },
          {
            path: 'article/:articleId',
            element: <StudentExamCategoryList />,
          },
          { path: 'exams/:categoryId', element: <StudentExamsList /> },
        ],
      },
      { path: 'pricing', element: <Pricing /> },
      { path: 'library/:id', element: <LibraryDetailNew /> }, // book route
      { path: 'library/:id/:chapter', element: <ChapterView /> }, // chapter route
      { path: '/library/:id/:chapter/:header', element: <HeaderView /> }, // header route
      { path: '/library/:id/:chapter/:header/:headertwo', element: <HeaderView /> }, // header 2 route
      { path: '/library/:id/:chapter/:header/:headertwo/:headerthree', element: <HeaderView /> }, // header 3 route

      {
        path: 'need-sub',
        element: <BuySub />,
      },
    ],
  });

  routeArray.push({ path: '*', element: <Navigate to="/" /> });
  return useRoutes(routeArray);
}
