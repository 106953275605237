import { Outlet } from 'react-router-dom';
// material
// import { styled } from '@mui/material/styles';
// components
// import Logo from '../components/Logo';
import Header from 'src/website/webinclude/webheader';
import Webfooter from 'src/website/webinclude/webfooter';

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  return (
    <>
      {/* <HeaderStyle>
        <Logo />
      </HeaderStyle> */}
      <Header />
      <Outlet />
      <Webfooter />
    </>
  );
}
