// routes
import Router from './routes';
import { ToastContainer } from 'react-toastify';
import ReactGa from 'react-ga4';
import 'react-toastify/dist/ReactToastify.css';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import './assets/Stylesheet/style.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/webstyle/webstyle.css';
import 'swiper/css/bundle';
import '../src/assets/webstyle/extra.css';
import '../src/assets/webstyle/WebResponsive.css';
import 'ckeditor5/ckeditor5.css';
import 'highlight.js/styles/github.css';
import { Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

// ----------------------------------------------------------------------

const CLIENT_ID = '211206027429-2pq4rbvf7hra1f7mj0679mfiefbmgj2o.apps.googleusercontent.com'; //process.env.GOOGLE_CLIENT_ID;

ReactGa.initialize('G-C65T1GXJSQ');

export default function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGa.send({
      hitType: 'pageview',
      page: location.pathname,
      title: location.pathname,
    });
  }, [location]);

  return (
    <>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <ThemeProvider>
          <ScrollToTop />
          <BaseOptionChartStyle />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div className="project_wrapper">
            <Suspense
              fallback={
                <Box
                  sx={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress />
                </Box>
              }
            >
              <Router />
            </Suspense>
          </div>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </>
  );
}
