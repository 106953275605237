import { DataService } from '../dataService';

export const websiteVisitService = {
  create: (data) => {
    return DataService.post('/website-visit', data);
  },
  getWebsiteVisit: (query) => {
    return DataService.get('/website-visit');
  },
};
