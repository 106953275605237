import { Storage } from "src/Storage";

const IMAGEURL = process.env.REACT_APP_API_IMAGEURL
const profileImage = Storage.get("authToken") && JSON.parse(Storage.get("authToken"));
// ----------------------------------------------------------------------
const account = {
  displayName: `${profileImage?.full_name}`,
  email: 'demo@minimals.cc',
  photoURL: `${IMAGEURL + profileImage.image}` //'/static/mock-images/avatars/avatar_default.jpg',
};

export default account;
